import React from 'react';

import Layout from '../components/layout';
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar';

const IndexPage = () => (
  <Layout>
    <Sidebar />

    <div id="wrapper">
      <section id="intro" className="wrapper style1 fullscreen fade-up">
        <div className="inner">
          <h1>Shattered Colossus</h1>
          <p>
            by <a href="http://tristanpoh.com">Tristan Poh</a>
          </p>
          <p>Coming Soon.</p>
        </div>
      </section>
    </div>

    <Footer />
  </Layout>
);

export default IndexPage;
